(<template>
  <div class="admin-chat">
    <vue-friendly-iframe v-if="iframeSrc"
                         :src="iframeSrc"
                         class="admin-chat__iframe" />
  </div>
</template>)

<script>
  export default {
    data() {
      return {
        iframeSrc: ''
      };
    },
    mounted() {
      this.iframeSrc = this.$router.resolve(
        this.$makeRoute({
          name: 'AdminChat',
          params: {
            id: this.$route.params.id
          }
        })
      ).href;
    }
  };
</script>

<style>
  .admin-chat iframe {
    width: 100%;
    height: 100%;
  }
</style>

<style scoped>
  .admin-chat {
    background-color: #fff;
  }

  .admin-chat__iframe {
    height: 100%;
  }
</style>
